import React, { useRef, useEffect, useState } from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { Box } from "grommet";
import posterImage from "../assets/images/poster.jpg"
import CloseIcon from "../assets/svgs/close.svg";
import Logo from "../assets/svgs/logo.svg";
import { useResponsive } from "../hooks";
import DivisionShowcase from "../components/DivisionShowcase";
import Button from "../components/Button";
import AnimatedTitle from "../components/AnimatedTitle";
import Divider from "../components/Divider";

const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
};

const mainVideo = "https://player.vimeo.com/progressive_redirect/playback/903214273/rendition/1080p/file.mp4?loc=external&log_user=0&signature=ca84e2e1f8c413423862e070a61c67cba9fc4fb519d806f5f7b1316f745086fe";

const VideoApp = () => {
  const videoParentRef = useRef();
  const [shouldUseImage, setShouldUseImage] = useState(false);
  useEffect(() => {
    // check if user agent is safari and we have the ref to the container <div />
    if (isSafari() && videoParentRef.current) {
      // obtain reference to the video element
      const player = videoParentRef.current.children[0];

      // if the reference to video player has been obtained
      if (player) {
        // set the video attributes using javascript as per the
        // webkit Policy
        player.controls = false;
        player.playsinline = true;
        player.muted = true;
        player.setAttribute("muted", ""); // leave no stones unturned :)
        player.autoplay = true;

        // Let's wait for an event loop tick and be async.
        setTimeout(() => {
          // player.play() might return a promise but it's not guaranteed crossbrowser.
          const promise = player.play();
          // let's play safe to ensure that if we do have a promise
          if (promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                // if promise fails, hide the video and fallback to <img> tag
                videoParentRef.current.style.display = "none";
                setShouldUseImage(true);
              });
          }
        }, 0);
      }
    }
  }, []);

  return shouldUseImage ? (
    <Box fill style={{backgroundImage: `url(${posterImage})`, backgroundSize: "cover", backgroundPosition: "center"}} alt="Muted Video" />
  ) : (
    <div
      className="fitVideo"
      ref={videoParentRef}
      dangerouslySetInnerHTML={{
        __html: `
        <video
          loop
          muted
          autoplay
          playsinline
          preload="metadata"
        >
        <source src="${mainVideo}" type="video/mp4" />
        </video>`
      }}
    />
  );
}

const FloatingCTA = () => {
  const [visible, setVisible] = useState(true);

  const dismiss = () => {
    setVisible(false);
  }

  if (!visible) return null;

  return (
    <div className={"absolute -translate-x-1/2 left-1/2 bottom-16 tablet:bottom-20"}>
      {/*Close button*/}
      <button className={"absolute right-2 top-2 -translate-y-1/2 translate-x-1/2 bg-primary px-2 size-7 rounded-full tablet:size-8 desktop:size-6"} onClick={dismiss}>
        <CloseIcon />
      </button>

      {/*CTA*/}
      <a href={"mailto:enquiries@bauvill.co.uk"} target={"_blank"} className={"block bg-light rounded-full px-8 py-3 text-lg font-medium whitespace-nowrap shadow-[0_3px_10px_rgb(0,0,0,0.5)] tablet:px-12 tablet:py-5 tablet:text-xl desktop:text-lg desktop:px-8 desktop:py-3"}>
        Get In Touch
      </a>
    </div>
  )
}

const HomeSplash = () => {
  const logoRef = useRef(null);
  const { isMobile } = useResponsive();

  return (
    <Box
      align="center"
      justify="center"
      width="100vw"
      style={{
        overflow: "hidden",
        position: "relative",
        height: "100vh",
        maxHeight: "-webkit-fill-available"
    }}
    >
      <VideoApp />
      <Box
         ref={logoRef}
        width={{
          min: isMobile ? "75%" : "50%",
          max: isMobile ? "300px" : null,
        }}
        style={{
          position: "absolute",

      }}
      >
        <Logo />
      </Box>
      <FloatingCTA />
    </Box>
  );
};

const AboutSection = () => {
  return (
    <div className={"content-container py-6 flex flex-col custom-h-screen tablet:flex-col-reverse desktop:flex-row desktop:py-18 desktop:pb-24"}>
      <AnimatedTitle as="h1" className="text-secondary font-bold text-6xl leading-[0.9] tablet:text-[16.67vh] tablet:leading-[0.9] desktop:text-[20vh] desktop:leading-[0.9] desktop:self-end">
        We <br/>Build <br/>Ideas
      </AnimatedTitle>

      <div className={"flex-1 mt-16 flex flex-col h-full justify-between tablet:justify-normal tablet:w-2/3 tablet:ml-auto desktop:flex-initial desktop:w-1/2"}>
        <p className={"block text-[0.75rem] text-secondary leading-[1.2] font-medium tablet:pl-0 tablet:text-lg tablet:leading-[1.2] tablet:mb-5 desktop:text-xl desktop:leading-[1.2]"}>
          We are Bauvill, an award winning main contractor proudly based in Kent. With over a decade of excellence in the construction industry, we offer a turn-key suite of services including new-builds, refurbishments, fit-outs, bespoke design solutions and facilities management. <br/><br/>Our highly experienced in-house team excels in delivering projects across the Health, Commercial, and Education sectors, consistently setting new standards for craftsmanship and project management.
        </p>
        <div className={"self-center mb-0 mt-4 tablet:mt-0 tablet:self-start -translate-x-[0.5rem]"}>
          <Button text={"Find Out More"} color={"secondary"} to={"/about"}  />
        </div>
      </div>
    </div>
  )
}

const Homepage = (props) => {

  return (
    <Layout isLandingPage footer={true}>
      <Seo title="Main Contractor Based in Kent" />
      <HomeSplash />
      <AboutSection />
      <Divider />
      <DivisionShowcase />
    </Layout>
  );
};

export default Homepage;
